import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Dropdown } from "@app/components/controls";
import { ErrorLabel } from "@app/components/error-label";
import { Typography } from "@app/components/typography";

import { FiAlertCircle } from "react-icons/fi";
import styles from "../form-builder.module.css";
import { showMappedReasons } from "../helpers";
import type { DropDownPartialProps } from "../types";

export const DropDownPartial = (props: DropDownPartialProps) => {
	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => (
				<div className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}>
					{props.showLabel ? (
						<Typography theme="textSm" className={`${styles.formLabel}`}>
							{props.title + (props.required && !props.hideAsterisk ? "*" : "")}
						</Typography>
					) : (
						<></>
					)}
					<Dropdown
						appendToParent={props.appendToParent}
						className={props.className}
						panelClassName={twMerge(
							"form-dropdown",
							props.filter && "filterable",
							props.panelClassName ?? "",
						)}
						disabled={props.disabled}
						options={props.options || []}
						{...field}
						iconSize={props.iconSize}
						iconColour={props.iconColour}
						filter={props.filter}
						filterPlaceholder={props.filterPlaceholder}
						onChange={(value) => {
							if (props.onChange) props.onChange(value);
							field.onChange(value);
						}}
						optionLabel={props.optionLabel || undefined}
						placeholder={props.placeholder}
						error={
							!!error ||
							showMappedReasons(
								props.mappedName ?? field.name,
								"#f04438",
								props.mappedReasons,
							) !== false
						}
					/>
					{error && (
						<ErrorLabel>
							<FiAlertCircle size={16} />
							<span>{error.message}</span>
						</ErrorLabel>
					)}
					{!error &&
						showMappedReasons(field.name, "#f04438", props.mappedReasons)}
				</div>
			)}
		/>
	);
};
