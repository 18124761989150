import { useState } from "react";

import { paths } from "@app/constants/paths";
import { Link, useLocation } from "react-router-dom";
import { HomeIcon } from "./home-icon";
import { RecipientsIcon } from "./recipients-icon";
import { SendReceiveIcon } from "./send-receive-icon";
import { TransactionsIcon } from "./transactions-icon";

import { useProfilePicture } from "@app/hooks/use-profile-picture";
import { AccountMenuDialog } from "../account-menu-dialog";
import { ProfilePicture } from "../profile-picture";
import styles from "./index.module.css";

const isActive = (pathname: string, route: string) => {
	if (route === paths().dashboard) return pathname === route;
	return pathname.startsWith(route);
};

export const BottomMenuBar = () => {
	const [showAccountMenu, setShowAccountMenu] = useState(false);
	const location = useLocation();
	const { data } = useProfilePicture();

	return (
		<>
			<nav aria-label="Main">
				<ul
					className={styles.container}
					data-is-pwa={!!(window.navigator as Navigator).standalone}
				>
					<li className={styles.item}>
						<Link
							aria-current={isActive(location.pathname, paths().dashboard)}
							className={styles.link}
							to={paths().dashboard}
						>
							<div className={styles.indicator} />
							<HomeIcon />
							Home
						</Link>
					</li>
					<li className={styles.item}>
						<Link
							aria-current={isActive(location.pathname, paths().transactions())}
							className={styles.link}
							to={paths().transactions()}
						>
							<div className={styles.indicator} />
							<TransactionsIcon />
							Transactions
						</Link>
					</li>
					<li className={styles.item}>
						<Link
							aria-current={isActive(
								location.pathname,
								paths().sendReceiveFunds(),
							)}
							className={`${styles.link} ${styles.button}`}
							aria-label="Send or receive funds"
							to={paths().sendReceiveFunds()}
						>
							<SendReceiveIcon />
						</Link>
					</li>
					<li className={styles.item}>
						<Link
							aria-current={isActive(location.pathname, paths().recipients)}
							className={styles.link}
							to={paths().recipients}
						>
							<div className={styles.indicator} />
							<RecipientsIcon />
							Recipients
						</Link>
					</li>
					<li className={styles.item}>
						<button
							type="button"
							className={styles.link}
							onClick={() => setShowAccountMenu(!showAccountMenu)}
							aria-current={isActive(location.pathname, paths().userSettings())}
						>
							<div className={styles.indicator} />
							<ProfilePicture src={data?.base64} />
							Account
						</button>
					</li>
				</ul>
			</nav>
			<AccountMenuDialog
				isOpen={showAccountMenu}
				onClose={() => setShowAccountMenu(false)}
			/>
		</>
	);
};
