import { useState } from "react";
import { useForm } from "react-hook-form";

import type { FormBuilderProps } from "@app/components/form-builder";
import { useSetRecipientNickname } from "@app/helpers";

import { EditNicknameModalView } from "./edit-nickname-modal-view";

import { useMediaQuery } from "@app/hooks/use-media-query";
import type { MappedReasons } from "@app/services";

interface NicknameForm {
	nickname: string;
}

export const EditNicknameModal = (props: {
	isEditNickname?: boolean;
	loading?: boolean;
	nickname?: string;
	recipientId?: number;
	onNicknameSaved: () => void;
	onCancel: () => void;
}) => {
	const isMobile = useMediaQuery();
	const [setRecipientNickname] = useSetRecipientNickname();

	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty },
	} = useForm<NicknameForm>({
		defaultValues: { nickname: props.nickname ?? "" },
		mode: "onChange",
	});

	const [mappedReasons, setMappedReasons] = useState<
		MappedReasons | undefined
	>();

	const onSaveNickname = (formData: NicknameForm) => {
		if (props.recipientId) {
			setRecipientNickname(
				props.recipientId,
				formData.nickname,
				(errors, mappedReasons) => {
					if (!errors || (errors.length === 0 && !mappedReasons)) {
						props.onNicknameSaved();
						setMappedReasons(undefined);
					} else {
						setMappedReasons(mappedReasons);
					}
				},
			);
		}
	};

	const NicknameInputFields: FormBuilderProps.FormInputProps[][] = [
		[
			{
				className: "edit-nickname-input",
				maxLength: 50,
				name: "nickname",
				placeholder: "eg. Travel account",
				required: true,
				theme: "none",
				title: "Nickname",
				type: "text",
			},
		],
	];

	const viewProps = {
		...props,
		errors: errors,
		formControl: control,
		formInputs: NicknameInputFields,
		isValid: isValid,
		isDirty: isDirty,
		loading: false,
		mappedReasons,
		title: "Nickname",
		handleSubmit: handleSubmit(
			(data) => {
				onSaveNickname(data);
			},
			(data) => {},
		),
	};

	return <EditNicknameModalView {...viewProps} isFullScreen={isMobile} />;
};
