import { ErrorLabel } from "@app/components/error-label";
import type { MappedReasons } from "@app/services";
import { FiAlertCircle } from "react-icons/fi";

export const showMappedReasons = (
	fieldName: string,
	iconColor: string,
	mappedReasons?: MappedReasons,
) => {
	const filteredMappedReasons = (
		mappedReasons ? mappedReasons[fieldName] || [] : []
	).filter((x) => !!x);

	if (filteredMappedReasons.length === 0) return false;

	return (
		<>
			{filteredMappedReasons.map((x, index) => (
				<ErrorLabel key={index}>
					<FiAlertCircle color={iconColor} size={16} />
					<span className="flex w-fit items-center">{x}</span>
				</ErrorLabel>
			))}
		</>
	);
};
