import { useForm } from "react-hook-form";

import { Button } from "@app/components/button";
import { FormBuilder } from "@app/components/form-builder";

import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { MappedReasons } from "@app/services";

import styles from "./index.module.css";

interface ChangeEmailForm {
	email?: string;
}

export const ChangeEmailAddressModal = (props: {
	isOpen: boolean;
	mappedReasons?: MappedReasons;
	onClose: () => void;
	onSendVerification: (email: string) => void;
}) => {
	const isMobile = useMediaQuery();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<ChangeEmailForm>({
		defaultValues: {},
		mode: "onChange",
	});

	const onHandleSubmitValid = (data: ChangeEmailForm) => {
		if (data.email) props.onSendVerification(data.email);
	};

	return (
		<Dialog
			fullscreen={isMobile}
			title="Change email address"
			isOpen={props.isOpen}
			onClose={props.onClose}
			actions={
				<>
					<Button variant="secondary" onClick={props.onClose}>
						Cancel
					</Button>
					<Button
						className={styles.submitButton}
						type="submit"
						form="change-email-address-modal-form"
					>
						Send verification
					</Button>
				</>
			}
		>
			<form
				id="change-email-address-modal-form"
				onSubmit={handleSubmit(onHandleSubmitValid)}
				noValidate
			>
				<FormBuilder
					errors={errors}
					formControl={control}
					formInputs={[
						[
							{
								maxLength: 50,
								name: "email",
								placeholder: "Please enter your email address",
								required: true,
								showLabel: true,
								theme: "none",
								title: "Email",
								type: "email",
							},
						],
					]}
					mappedReasons={props.mappedReasons}
				/>
			</form>
		</Dialog>
	);
};
