import { type ReactNode, useState } from "react";
import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Dropdown } from "@app/components/controls";
import { ErrorLabel } from "@app/components/error-label";
import { Typography } from "@app/components/typography";
import type { DropdownGroupOption, DropdownOption } from "@app/entities";

import { FiAlertCircle, FiCheck } from "react-icons/fi";
import styles from "../form-builder.module.css";
import { showMappedReasons } from "../helpers";
import type { DropDownOptionGroupedPartialProps } from "../types";

export const DropDownOptionGroupedPartial = (
	props: DropDownOptionGroupedPartialProps,
) => {
	const itemTemplate = (option: DropdownOption, value: any): ReactNode => {
		if (option.value === (value as number)) {
			return (
				<>
					{option.label}
					<FiCheck size={20} color="#888888" />
				</>
			);
		}

		return <>{option.label}</>;
	};

	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				const [focused, setFocused] = useState(false);

				return (
					<div
						className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}
					>
						{props.showLabel ? (
							<div className={styles.dropdownLabel}>
								<Typography
									theme="textSm"
									className={`${styles.formLabel} ${
										focused ? "text-teal-550" : "text-gray-1100"
									}`}
								>
									{props.title +
										(props.required && !props.hideAsterisk ? "*" : "")}
								</Typography>
								{props.popover && props.popover}
							</div>
						) : (
							<></>
						)}
						<Dropdown
							appendToParent={props.appendToParent}
							className={props.className}
							panelClassName={twMerge(
								"form-dropdown",
								props.filter && "filterable",
								props.panelClassName ?? "",
							)}
							disabled={props.disabled}
							options={props.options || []}
							{...field}
							iconSize={props.iconSize}
							iconColour={props.iconColour}
							filter={props.filter}
							filterPlaceholder={props.filterPlaceholder}
							onChange={(value) => {
								if (props.onChange) props.onChange(value);
								field.onChange(value);
							}}
							onHide={() => setFocused(false)}
							onShow={() => setFocused(true)}
							optionLabel="label"
							optionGroupLabel="label"
							optionGroupChildren="items"
							optionGroupTemplate={(option, index) => {
								const genericOption = option as DropdownGroupOption;

								return (
									<>
										{props.optionGroupTemplate ? (
											props.optionGroupTemplate(option, index)
										) : (
											<Typography
												className="font-semibold text-gray-1100"
												theme="textLg"
											>
												{genericOption.label}
											</Typography>
										)}
									</>
								);
							}}
							placeholder={props.placeholder}
							valueTemplate={(option, selected) => {
								const optionTyped = option as DropdownOption;

								if (option) {
									return (
										<>
											{props.valueTemplate
												? props.valueTemplate(option, selected)
												: optionTyped.label}
										</>
									);
								}
								return <>{props.placeholder}</>;
							}}
							itemTemplate={(option, value) => {
								return (
									<>
										{props.itemTemplate
											? props.itemTemplate(option, value)
											: itemTemplate(option, value)}
									</>
								);
							}}
							panelFooterTemplate={props.panelFooterTemplate}
							dropdownRef={props.dropdownRef}
							error={
								!!error ||
								showMappedReasons(
									props.mappedName ?? field.name,
									"#f04438",
									props.mappedReasons,
								) !== false
							}
						/>
						{error && (
							<ErrorLabel>
								<FiAlertCircle color="#f04438" size={16} />
								<span>{error.message}</span>
							</ErrorLabel>
						)}
						{!error &&
							showMappedReasons(field.name, "#f04438", props.mappedReasons)}
					</div>
				);
			}}
		/>
	);
};
