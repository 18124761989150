import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { type FieldErrors, useForm } from "react-hook-form";

import type { MappedReasons } from "@app/services";

import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { FormBuilder } from "@app/components/form-builder";

import { useMediaQuery } from "@app/hooks/use-media-query";

interface ChangePasswordForm {
	currentPassword?: string;
	newPassword?: string;
	confirmNewPassword?: string;
}

export const ChangePasswordModal = (props: {
	isOpen: boolean;
	mappedReasons?: MappedReasons;
	onClose: () => void;
	onSave: (passwords: ChangePasswordForm) => void;
}) => {
	const isMobile = useMediaQuery();

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
		clearErrors,
	} = useForm<ChangePasswordForm>({
		defaultValues: {},
		mode: "onChange",
	});

	const [mappedReasons, setMappedReasons] = useState<MappedReasons | undefined>(
		props.mappedReasons,
	);

	const onClearErrors = (name?: string) => {
		if (name) {
			const newMappedReasons = { ...mappedReasons };

			newMappedReasons[name] = undefined;

			setMappedReasons(newMappedReasons);
		} else setMappedReasons(undefined);
	};

	const passwordsDoNotMatch = () => {
		const values = getValues();

		return (
			(isEmpty(values.newPassword) && !isEmpty(values.confirmNewPassword)) ||
			(!isEmpty(values.newPassword) && isEmpty(values.confirmNewPassword)) ||
			(!isEmpty(values.newPassword) &&
				!isEmpty(values.confirmNewPassword) &&
				values.newPassword === values.confirmNewPassword)
		);
	};

	useEffect(() => {
		if (!props.isOpen) {
			reset({
				currentPassword: undefined,
				newPassword: undefined,
				confirmNewPassword: undefined,
			});

			clearErrors();
			setMappedReasons(undefined);
		}
	}, [props.isOpen]);

	return (
		<Dialog
			fullscreen={isMobile}
			isOpen={props.isOpen}
			title="Change password"
			onClose={props.onClose}
			actions={
				<>
					<Button variant="secondary" onClick={props.onClose}>
						Cancel
					</Button>
					<Button type="submit" form="change-password-form">
						Save
					</Button>
				</>
			}
		>
			<form
				id="change-password-form"
				onSubmit={handleSubmit(props.onSave)}
				noValidate
			>
				<FormBuilder
					errors={errors}
					formControl={control}
					formInputs={[
						[
							{
								maxLength: 50,
								name: "currentPassword",
								placeholder: "Enter your current password",
								required: true,
								showLabel: true,
								theme: "none",
								title: "Current Password",
								type: "password",
							},
						],
						[
							{
								customErrorMessage: "Password doesn’t match your new password",
								maxLength: 50,
								name: "newPassword",
								placeholder: "Enter a new password",
								required: true,
								showLabel: true,
								theme: "none",
								title: "New Password",
								type: "password",
								onCustomValidationRule: passwordsDoNotMatch,
								onChange: () => {
									clearErrors("confirmNewPassword");
									clearErrors("newPassword");
									onClearErrors("newPassword");
								},
							},
						],
						[
							{
								customErrorMessage: "Password doesn’t match your new password",
								iconSize: 20,
								maxLength: 50,
								name: "confirmNewPassword",
								placeholder: "Confirm your new password",
								required: true,
								showLabel: true,
								theme: "none",
								title: "Confirm New Password",
								type: "password",
								onCustomValidationRule: passwordsDoNotMatch,
								onChange: () => {
									clearErrors("newPassword");
									onClearErrors("newPassword");
								},
							},
						],
					]}
					mappedReasons={props.mappedReasons}
				/>
			</form>
		</Dialog>
	);
};
